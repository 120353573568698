<template>
  <div class="indexBox">
     <!-- 新年装饰 -->
    <div class="dark" v-if="controlDark">
      <div class="background"></div>
      <img src="../../static/index/door.png"  alt="">
      <img class="closer3" @click="()=>{controlDark = false}" src="../../static/index/closer3.png" alt="">
    </div>
    <div class="bigImg">
      <div class="floatWindow" v-if="closerControl">
温馨提示:您现在访问的是香港网站，红狮集团-香港金银业贸易场AA类081号持牌行员，现货黄黄金白银投资属于香港合规投资产品，依据香港条例运营，未必符合您地区法规投资需谨慎。
        <img @click="closer" src="../../static/index/closer.png" alt="">
      </div>
      <swiper class="swiper-container" v-if="swiperData" ref="mySwiper" :options="swiperOption"> 
        <div class="swiper-slide" v-for="(item,i) in swiperData" :key="i" @click="jumpClick(item.url)">
          <img :src="item.imgUrl" />
        </div>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div v-if="imgData" class="onlyBanner">
        <img @click="jumpClick(imgData.url)" :src="imgData.imgUrl !== '' ? imgData.imgUrl: ''" alt="">
      </div>
    </div>
    <div class="indexTitle">
      <!-- 轻松黄金交易，品鉴愉悦人生 -->
      <img style="width:65%" src="../../static/index/qshjjy.png" alt="">
    </div>
    <div class="fourBox">
      <div class="fbTop">
        <div>
          <!-- <img src="../../static/index/wap/baozhiIcon.png" alt="">
          <span>保值性</span>
          <span>时光流转 价值永存</span>
          <p>
            从古代起黄金便已开始被当作货币来交易，流传千年经久不衰，他的价值不论国界，不分币种，是全世界公认保值性极高的贵金属。
          </p> -->
          <img style="width:100%" src="../../static/index/craig1.png" alt="">
        </div>
        <div>
          <img style="width:100%" src="../../static/index/craig2.png" alt="">
        </div>
      </div>
      <div class="fbBottom">
        <div>
          <img style="width:100%" src="../../static/index/craig3.png" alt="">
        </div>
        <div>
          <img style="width:100%" src="../../static/index/craig4.png" alt="">
        </div>
      </div>
    </div>
    <div class="indexTitle">
      <!-- 专注黄金交易，一站式管家服务 -->
      <img style="width:65%" src="../../static/index/zhhjjy.png" alt="">
    </div>
    <div class="serveBox">
      <div class="sbControl">
        <ul @mouseover="tabClick(1)"
            :class="controlDiv === 1 ? 'ulBottom' : ''">
          <li>
            <span>黃金交易平台</span>
          </li>
          <li>香港贸易场081行员</li>
          <li>
            <i :class="controlDiv === 1 ? 'svgColor' : ''" 
               class="iconfont icon-zhuanyequanwei"></i>
          </li>
        </ul>
        <ul @mouseover="tabClick(2)"
            :class="controlDiv === 2 ? 'ulBottom' : ''">
          <li>
            <span>资金安全</span>
          </li>
          <li>出金自由</li>
          <li>
            <i :class="controlDiv === 2 ? 'svgColor' : ''" 
               class="iconfont icon-49"></i>
          </li>
        </ul>
        <ul @mouseover="tabClick(3)"
            :class="controlDiv === 3 ? 'ulBottom' : ''">
          <li>
            <span>服务专业</span>
          </li>
          <li>7*24小时客服</li>
          <li>
            <i :class="controlDiv === 3 ? 'svgColor' : ''" 
               class="iconfont icon-rencaifuwu"></i>
          </li>
        </ul>
        <ul @mouseover="tabClick(4)"
            :class="controlDiv === 4 ? 'ulBottom' : ''">
          <li>
            <span>丰富活动</span>
          </li>
          <li>低点差，活动优惠</li>
          <li>
            <i :class="controlDiv === 4 ? 'svgColor' : ''" 
               class="iconfont icon-huodongliwu"></i>
          </li>
        </ul>
        <ul @mouseover="tabClick(5)"
            :class="controlDiv === 5 ? 'ulBottom' : ''">
          <li>
            <span>强大软件</span>
          </li>
          <li>全新智能化软件</li>
          <li>
            <i :class="controlDiv === 5 ? 'svgColor' : ''" 
               class="iconfont icon-gongyouyunzhujishenqingliucheng-02"></i>
          </li>
        </ul>
      </div>
      <div class="sbShow">
        <div class="showChild" v-if="controlDiv === 1">
          <div class="showChildDiv1">
            <!-- 红狮集团，获香港贸易场监管，属贸易场AA类行员<br/>
            多次获得“最佳贵金属交易平台金企” -->
            <img style="width:68%" src="../../static/index/hsgj1.png?v=2" alt="">
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 2">
          <div class="showChildDiv2">
            <img style="width:68%" src="../../static/index/hsgj2.png?v=3" alt="">
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 3">
          <div class="showChildDiv3">
            <img style="width:68%" src="../../static/index/hsgj3.png" alt="">
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 4">
          <div class="showChildDiv4">
            <img style="width:68%" src="../../static/index/hsgj4.png" alt="">
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 5">
          <div class="showChildDiv5">
            <img style="width:68%" src="../../static/index/hsgj5.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="webSocket" :class="websocketScrollTop ? 'fixedWebSocket':''">
      <div class="wssBox">
        <ul>
          <li class="liFirst">
            <span>伦敦金</span>
            <img src="../../static/index/ldj.png" alt="">
          </li>
          <li class="liSecond">
            <p>
              <span :class="London.controlPic ? 'classRed' : 'classGreen' ">{{London.gold}}</span>
              <img :src="London.controlPic ? LondonImg[0] : LondonImg[1]" alt="">
            </p>
            <p>
              <span>{{London.goldRise}}</span>
              <span>{{London.goldPer}}</span>
            </p>
          </li>
        </ul>
        <div class="websocketLine"></div>
        <ul>
          <li class="liFirst">
            <span>伦敦银</span>
            <img src="../../static/index/ldy.png" alt="">
          </li>
          <li class="liSecond">
            <p>
              <span :class="London.controlPicOther ? 'classRed' : 'classGreen' ">{{London.silver}}</span>
              <img :src="London.controlPicOther ? LondonImg[0] : LondonImg[1]" alt="">
            </p>
            <p>
              <span>{{London.silverRise}}</span>
              <span>{{London.silverPer}}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import { base64ToFloat32 } from '../../utils/tools'
  import { analyticalData } from '../../utils/tools'
  import {swiperBanner} from '../../api/info'
  export default {
    name: 'Index',
    metaInfo: {  
      title: '红狮金业官网,黄金交易平台,红狮启富,持牌正规安全首选红狮金业',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易,贵金属投资,贵金属平台,现货黄金开户,正规靠谱外汇交易,正规网上交易平台。' },  
        { name: 'description', content: '红狮金业是专业值得信赖的国际投资交易平台，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },  
    data() {
      return {
        LondonImg:[
          require('../../static/index/jiaohu/up.png'),
          require('../../static/index/jiaohu/down.png'),
        ],
        selectedImg: require('../../static/index/yuandian.png'),
        controlSwiper: true,
         controlDark: true,
        // swiper 参数
        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: true,
          grabCursor:true,
          loop: true,
          preventClicks : true,
           //自动播放
          autoplay: {
            delay: 3000, //延迟播放下一张图片
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
            // type:'custom',
            // renderCustom: function (swiper, current, total) {
            //   if(current === 1){
            //     return '<span class="swiperOne"><li><span></span></li><span class="swiperOneSpan"></span><span></span></span>';
            //   }else if(current === 2){
            //     return `<span class="swiperTwo"><span></span><li><span></span></li><span></span></span>`;
            //   }else{
            //     return `<span class="swiperThree"><span></span><span class="swiperThreeSpan"></span><li><span></span></li></span>`;
            //   }
            // }
          },
        },
        // 控制serveBox
        controlDiv:1,
        // 控制websocket模块是否定位
        websocketScrollTop:false,
        craigCao: 0,
        London:{
          gold: '--',
          silver: '--',
          goldRise: '0.0',
          silverRise: '0.0',
          goldPer: '0.00%',
          silverPer: '0.00%',
          controlPic: true,
          controlPicOther: true,
        },
        closerControl: true,
        goldCl:'',
        silverCl:'',
        ahref:'https://www.rlcltd001.com/#/subject/worldCup/wap?craig=' + window.location.origin,
        swiperData:'',
        imgData:'',
      }
    },
    created(){
      // 自动切换serveBox
      setInterval(this.updateDiv, 4000);
      // setTimeout(()=>{
      //   document.getElementsByClassName("navBarWrap")[0].style.height = '';
      // },50)
      this.initSwiper();
    },
    mounted() {
      setTimeout(() => {
        this.controlDark = false;
      }, 5000); // 4 秒后关闭弹窗
      // 初始化websocket
      this.initWebSocket('wss://tradeapi-pm-hq3.mcitj.com:4433/quote');
      window.addEventListener("scroll", this.handleScroll);
      // 在一定时间后关闭弹窗
      
    },
    methods: {
      // 获取swiper接口
      initSwiper(){
        const option = {
          languageidentification:1,
          type:35,
          region:1
        }
        swiperBanner(option).then(res=>{
          if(res.Status === 0){
            if(res.Data.length>1){
              this.swiperData = res.Data;
            }else{
              this.imgData = res.Data[0];
            }
          }
        }).catch(err=>{
          console.log(err);
        })
      },
      closer(){
        this.closerControl = !this.closerControl;
      },
      handleScroll () { //改变元素#searchBar的top值
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if(scrollTop > 900){
          this.websocketScrollTop = true;
        }else{
          this.websocketScrollTop = false;
        }
      },
      // 切换serveBox的方法
      updateDiv(){
        if(this.controlDiv < 5){
          this.controlDiv = this.controlDiv + 1;
        }else{
          this.controlDiv = 1;
        } 
      },
      // websocket 方法
      initWebSocket(src) {
        const wsuri = src;
        // this.websock = new WebSocket(wsuri);
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onopen = this.websocketonopen;
        this.websock.onerror = this.websocketonerror;
        this.websock.onclose = this.websocketclose;
      },
      websocketonopen() {
        let actions = { 't': 7, 'd': { 'k': 0 }};
        this.websocketsend(JSON.stringify(actions));
      },
      websocketonerror() {
        let _this = this;
        console.log("连接建立失败");
        this.initWebSocket('wss://tradeapi-pm-hq2.mcrubly.com:4433/quote');
      },
      websocketonmessage(e) {
        const data = JSON.parse(e.data)
        if (data.t === 0) {
          for (const iterator of data.d) {
            const blob = window.atob(iterator.q)
            const symbol = analyticalData(data.d[0].q||"",5);
            // console.log(symbol);
            if(symbol.SymbolName.slice(0,4) == 'GOLD'){
              // 黄金
              this.London.gold = symbol.Bid.toFixed(3);
              let gold = symbol.Bid;
              if(Number(gold) > Number(this.London.gold)){
                this.London.controlPic = true;
              }else{
                this.London.controlPic = false;
              }
            }else if(symbol.SymbolName.slice(0,6) == 'SILVER'){
              // 白银
              this.London.silver = symbol.Bid.toFixed(3);
              let silver = symbol.Bid;
              if(Number(silver) > Number(this.London.silver)){
                this.London.controlPicOther = true;
              }else{
                this.London.controlPicOther = false;
              }
            }
          }
        }else if (data.t === 5) {
          console.log('t=5');
          let open = data.d.filter(item => {
            if (/(GOLD|SILVER)/.test(item.n)) {
              return item.n
            }
          })
          open.forEach(element => {
            if(element.n == 'GOLD'){
              // 黄金
              this.goldCl =  element.cl;
            }else if(element.n == 'SILVER'){
              // 白银
              this.silverCl = element.cl;
            }
          });
        }
        // 黄金 计算涨幅;
        this.London.goldRise = (Number(this.London.gold) - Number(this.goldCl)).toFixed(2);
        // 计算百分比（涨幅/昨收）
        this.London.goldPer = ((this.London.goldRise/this.goldCl)*100).toFixed(2)+ '%';
        // 白银 涨幅
        this.London.silverRise =  (Number(this.London.silver) - Number(this.silverCl)).toFixed(3);
        // 计算百分比（涨幅/昨收）
        this.London.silverPer = ((this.London.silverRise/this.silverCl)*100).toFixed(2)+ '%';
      },
      websocketsend(Data) {
        this.websock.send(Data);
      },
      websocketclose(e) {
        console.log("断开连接", e);
      },
      // tabbar点击事件
      tabClick(attr){
        switch(attr){
          case 1:
            this.controlDiv = attr;
            break;
          case 2:
            this.controlDiv = attr;
            break;
          case 3:
            this.controlDiv = attr;
            break;
          case 4:
            this.controlDiv = attr;
            break;
          case 5:
            this.controlDiv = attr;
            break;
        }
      },
      // 点击跳转到专题页
      jumpClick(attr){
        if(attr === 1){
          let origin = window.location.origin;
          window.location.href = 'https://sem.' + origin.split('.')[1] + '.' + origin.split('.')[2] + '/subject/chj_2112';
        }else{
          // this.$router.push({
          //   name:'startPageWap',
          // })
          window.open(attr);
        }
      }
    },
    computed: {
      //计算高度
      computeHeight() {
        let screenHeight = document.body.scrollTop;
        console.log(screenHeight);
        if (screenHeight > 415) {
          return '550px';
        } else {
          return '250px';
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .indexBox{
    
    width: 100%;
    box-sizing: border-box;
    .dark {
    .background{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); /* 背景颜色设置为半透明黑色 */
    }
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width:343px;
      opacity: 1;
      position: relative;
    }
    .closer3 {
      width: 30px;
     position: relative;
      top: -260px;
    right: 0%;
      cursor: pointer;
    }
  }
    .bigImg{
      width: 7.5rem;
      height: 9.2rem;
      // background: url('../../static/index/wap/banner.jpg') no-repeat;
      background-size: cover;
      box-sizing: border-box;
      overflow: hidden;
      /deep/ .swiper-container{
        .swiper-wrapper{
          img{
            width: 100%;
            height: auto;
          }
        }
        .swiper-pagination{
          .swiper-pagination-bullet-active{
            background-color: #e1352a;
          }
        }
      }
      .onlyBanner{
        img{
          width: 100%;
          height: auto;
        }
      }
      .floatWindow {
        width: 140%;
        margin: 0 auto;
        font-size: 13px;
        text-align: center;
        padding: 8px 0px;
        color: #ff9b1c;
        background-color: rgba(240, 215, 132, 0.4);
        white-space: nowrap;
        animation: 15s wordsLoop linear infinite normal;
        img {
          cursor: pointer;
        }
      }
      @keyframes wordsLoop {
        0% {
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
        }
      }
      @-webkit-keyframes wordsLoop {
        0% {
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
        }
      }
    }
    .indexTitle{
      font-size: 0.38rem;
      color: #121212;
      box-sizing: border-box;
      padding: 25px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fourBox{
      width: 7.5rem;
      height: 11.1rem;
      background: url('../../static/index/wap/secondBanner.png') no-repeat;
      background-size: cover;
      box-sizing: border-box;
      padding: 0.6rem 0.4rem;
      .fbTop{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.3rem;
        div{
          width: 3.2rem;
          height: 4.8rem;
          box-sizing: border-box;
          padding: 0 0.48rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          img{
            width: 0.5rem;
          }
          span:nth-child(2){
            font-size: 0.28rem;
            color: #121212;
            margin: 0.21rem 0 0.13rem;
          }
          span:nth-child(3){
            font-size: 0.2rem;
            color: #6d6d6d;
          }
          p{
            font-size: 0.18rem;
            line-height: 0.3rem;
            color: #939393;
            text-align: justify;
            margin-top: 0.4rem;
          }
        }
        div:nth-child(1){
          background: url('../../static/index/wap/zBanner1.png') no-repeat;
          background-size: cover;
        }
        div:nth-child(2){
          background: url('../../static/index/wap/zBanner2.png') no-repeat;
          background-size: cover;
          box-sizing: border-box;
          padding-top: 0.26rem;
        }
      }
      .fbBottom{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div{
          width: 3.2rem;
          height: 4.8rem;
          box-sizing: border-box;
          padding: 0 0.48rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          img{
            width: 0.5rem;
          }
          span:nth-child(2){
            font-size: 0.28rem;
            color: #121212;
            margin: 0.21rem 0 0.13rem;
          }
          span:nth-child(3){
            font-size: 0.2rem;
            color: #6d6d6d;
          }
          p{
            font-size: 0.18rem;
            line-height: 0.3rem;
            color: #939393;
            text-align: justify;
            margin-top: 0.4rem;
          }
        }
        div:nth-child(1){
          background: url('../../static/index/wap/zBanner3.png') no-repeat;
          background-size: cover;
        }
        div:nth-child(2){
          background: url('../../static/index/wap/zBanner4.png') no-repeat;
          background-size: cover;
        }
      }
    }
    .serveBox{
      width: 7.5rem;
      .sbControl{
        width: 7.5rem;
        // margin: 0 auto;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        border-bottom: 1px solid #e2e2e2;
        white-space: nowrap;
        overflow-x: auto; 
        overflow-y: hidden;
        .ulBottom{
          border-bottom: 0.04rem solid #d52226;
        }
        ul{
          width: 25%;
          display: inline-block;
          height: 1.5rem;
          cursor: pointer;
          li{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-size: 0.15rem;
            color: #6d6d6d;
            .svgColor{
              color: #d52226;
            }
            span{
              font-size: 0.28rem;
              color: #121212;
            }
            i{
              font-size: 0.26rem;
            }
          }
          li:nth-child(2){
            margin: 0.1rem 0 0.3rem;
          }
        }
        ul:last-child{
          margin-left: 0.3rem;
        }
        ul:nth-child(4){
          margin-left: 0.3rem;
        }
      }
      // 隐藏滚动条
      .sbControl::-webkit-scrollbar {
        display:none;
      }
      .sbShow{
        width: 100%;
        .showChild{
          width: 100%;
          height: 4.8rem;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          div{
            width: 100%;
            font-size: 0.22rem;
            height: 4.0rem;
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            line-height: 0.5rem;
            color: #121212;
            background-size: cover;
            box-sizing: border-box;
            padding-top: 0.7rem;
          }
          .showChildDiv1{
            background: url('../../static/index/wap/textBanner1.png') no-repeat;
            background-size: cover;
          }
          .showChildDiv2{
            background: url('../../static/index/wap/textBanner2.png') no-repeat;
            background-size: cover;
          }
          .showChildDiv3{
            background: url('../../static/index/wap/textBanner3.png') no-repeat;
            background-size: cover;
          }
          .showChildDiv4{
            background: url('../../static/index/wap/textBanner4.png') no-repeat;
            background-size: cover;
          }
          .showChildDiv5{
            background: url('../../static/index/wap/textBanner5.png') no-repeat;
            background-size: cover;
          }
        }
      }
    }
    .webSocket{
      width: 7.5rem;
      height: 2.4rem;
      background: url('../../static/index/ldjyBanner.png') no-repeat;
      background-size: cover;
      position: fixed;
      bottom: 0;
      display: none;
      .wssBox{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        ul{
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          li{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .liFirst{
            span{
              font-size: 0.28rem;
              color: #121212;
            }
            img{
              width: 0.7rem;
              height: auto;
            }
          }
          .liSecond{
            height: 0.7rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            margin-left: 0.2rem;
            p{
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0px;
              span{
                font-size: 0.18rem;
              }
              .classRed{
                color: #d52226;
                font-size: 0.24rem;
                font-weight: 600;
              }
              .classGreen{
                color: #18a450;
                font-size: 0.24rem;
                font-weight: 600;
              }
              img{
                width: 0.2rem;
                height: auto;
                margin-left: 10px;
              }
            }
            p:nth-child(2){
              // margin-bottom: 10px;
              span{
                font-size: 0.16rem;
                color: #6d6d6d;
                margin-right: 10px;
              }
            }
          }
        }
        .websocketLine{
          height: 0.8rem;
          width: 1px;
          background: #e2e2e2;
        }
      }
    }
    .fixedWebSocket{
      position: inherit;
    } 
  }
  /deep/ #box{
    margin-top: 100px;
  }
  
  // 设置swiper分页器的样式
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: 55px;
  }
  /deep/ .swiperOne{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      width: 4px;
      height: 4px;
      background-color: #999999;
      border-radius: 50%;
    }
    li{
      width: 20px;
      height: 20px;
      border: 2px solid #d52226;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        width: 4px;
        height: 4px;
        background-color: #d52226;
        border-radius: 50%;
      }
    }
    .swiperOneSpan{
      margin: 0 30px;
    }
  }
  /deep/ .swiperTwo{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      width: 4px;
      height: 4px;
      background-color: #999999;
      border-radius: 50%;
    }
    li{
      width: 20px;
      height: 20px;
      border: 2px solid #d52226;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 30px;
      span{
        width: 4px;
        height: 4px;
        background-color: #d52226;
        border-radius: 50%;
      }
    }
  }
  /deep/ .swiperThree{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      width: 4px;
      height: 4px;
      background-color: #999999;
      border-radius: 50%;
    }
    li{
      width: 20px;
      height: 20px;
      border: 2px solid #d52226;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        width: 4px;
        height: 4px;
        background-color: #d52226;
        border-radius: 50%;
      }
    }
    .swiperThreeSpan{
      margin: 0 30px;
    }
  }
</style>